import { lazy } from 'react';
import { resumePolicyPathNames } from './generic/Helpers/ResumePolicyHelper';
// Implementing lazy loading of all the components
const Home = lazy(() => import('./Home'));
const About = lazy(() => import('./About'));
const Contact = lazy(() => import('./Contact'));
const NewQuote = lazy(() => import('./generic'));
const SubmitClaim = lazy(() => import('./SubmitClaim'));
const FastFactsProducts = lazy(() => import('./FastFactsProducts'));
const FastFactsOptions = lazy(() => import('./FastFactsOptions'));
const Faq = lazy(() => import('./Faq'));
const TermsAndConditions = lazy(() => import('./TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const Disclosure = lazy(() => import('./Disclosure'));
const FastFacts = lazy(() => import('./FastFacts'));
const LoadingError = lazy(() => import('./LoadingError'));
const GPaymentForm = lazy(() => import('./generic/PaymentForm'));
const GPaymentCheckout = lazy(() => import('./generic/Checkout'));
const GPaymentStatus = lazy(() => import('./generic/PaymentConfirmation'));
const PaymentSummary = lazy(() => import('./generic/Components/SummaryPages/Pmt_PaymentSummary'));
const FlushCache = lazy(() => import('./generic/FlushCache'));
const ResumePolicy = lazy(() => import('./generic/ResumePolicy'));
const AgentLogin = lazy(() => import('./generic/Authorization/AgentLogin'));
const PolicyWizardRedirection = lazy(() => import('./generic/Components/PolicyWizardRedirection'));
const Details = lazy(() => import('./Details'));
const ClaimSummary = lazy(() => import('./generic-claims/ClaimSummary'));
const ReferralSummaryDetailsPage = lazy(
	() => import('./generic/Components/SummaryPages/Ref_ReferralSummaryDetailsPage')
);
const DescriptionNavBaProducts = lazy(() => import('./DescriptionNavBarProducts'));
const DescriptionNavBarOptions = lazy(() => import('./DescriptionNavBarOptions'));

const Config = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/',
			element: <Home />,
			exact: true
		},
		{
			path: '/about',
			element: <About />
		},
		{
			path: '/product',
			element: <FastFactsProducts />
		},
		{
			path: '/faq',
			element: <Faq />
		},
		{
			path: '/fast-facts',
			element: <FastFacts />
		},
		{
			path: '/contact',
			element: <Contact />
		},
		{
			path: '/details',
			element: <Details />
		},
		{
			path: '/new-buy-insurance/:productId/:productOptionId',
			element: <NewQuote />
		},
		{
			path: '/new-buy-insurance/:productId',
			element: <NewQuote />
		},
		{
			path: '/new-buy-insurance',
			element: <NewQuote />
		},
		{
			path: '/submit-claim',
			element: <SubmitClaim />
		},
		{
			path: '/fast-facts-products/:id',
			element: <FastFactsProducts />
		},
		{
			path: '/fast-facts-options/:id',
			element: <FastFactsOptions />
		},
		{
			path: '/privacy-policy',
			element: <PrivacyPolicy />
		},
		{
			path: '/disclosure',
			element: <Disclosure />
		},
		{
			path: '/terms-and-conditions',
			element: <TermsAndConditions />
		},
		{
			path: '/loading-error',
			element: <LoadingError />
		},
		{
			path: '/g-payments/summary',
			element: <PaymentSummary />
		},
		{
			path: '/g-payments/status',
			element: <GPaymentStatus />
		},
		{
			path: '/g-payments/form',
			element: <GPaymentForm />
		},
		{
			path: '/g-payments/checkout',
			element: <GPaymentCheckout />
		},
		{
			path: '/flush-cache',
			element: <FlushCache />
		},
		{
			path: `/${resumePolicyPathNames.resumePolicyDraft}/:instanceid/:reference`,
			element: <ResumePolicy />
		},
		{
			path: `/${resumePolicyPathNames.resumeUCReferredPolicy}/:policynumber/:policySaleInstanceId`,
			element: <ResumePolicy />
		},
		{
			path: `/${resumePolicyPathNames.resumeUWCReferredPolicy}/:policynumber/:policySaleInstanceId`,
			element: <ResumePolicy />
		},
		{
			path: `/${resumePolicyPathNames.resumeUWCEndorsement}/:policynumber/:policySaleInstanceId`,
			element: <ResumePolicy />
		},
		{
			path: `/${resumePolicyPathNames.resumeUCQuotePolicy}/:policynumber/:policySaleInstanceId`,
			element: <ResumePolicy />
		},
		{
			path: '/agent/login',
			element: <AgentLogin />
		},
		// NOTE - For time being, we are taking out this functionality. We will enable it in future.
		// {
		// 	path: '/agent/registration',
		// 	element: AgentRegistration
		// },
		// {
		// 	path: '/agent/forgot-password',
		// 	element: AgentForgotPassword
		// },
		// {
		// 	path: '/agent/reset-password',
		// 	element: AgentResetPassword
		// },
		{
			path: '/policy-wizard',
			element: <PolicyWizardRedirection />
		},
		{
			path: '/claim-summary',
			element: <ClaimSummary />
		},
		{
			path: '/description/:productId',
			element: <DescriptionNavBaProducts />
		},
		{
			path: '/description/:productId/:productTypeId?/:productOptionId?',
			element: <DescriptionNavBarOptions />
		},
		{
			path: '/referral-summary-details',
			element: <ReferralSummaryDetailsPage />
		}
	]
};

export default Config;
