import store from 'app/store';
import { addPolicySaleAttachmentsBase64 } from 'app/store/briisk/actions/formAction';
import { searchLookups } from 'app/store/briisk/actions/formAction';
import { saveForm } from 'app/store/briisk/formSlice';
import { debounce } from 'lodash';
import { getAttributeValueByCode } from './RiskInfoHelper';
import { toast } from 'react-toastify';

export const setRiskFields = ({
	dispatch,
	channelProducts,
	setFactors,
	setRisks,
	productId,
	isPolicyDraft,
	draftPolicyDetails,
	draftPolicy,
	resumePolicyPathNames
}) => {
	let formValuesFromDraftedPolicy = {};

	// binding & dispatching factors...
	const currRisksObj = channelProducts?.value?.find(
		channelProduct => channelProduct?.product?.instanceId === productId
	);

	dispatch(setFactors(currRisksObj.attributes.length ? { [`factors-0`]: currRisksObj.attributes } : null));

	// binding & dispatching risks...
	const currRisks = [...currRisksObj.risks]
		// eslint-disable-next-line
		.map((risk, i) => {
			// If loading the data from policy draft,
			// set the Risks according to policy draft.
			if (isPolicyDraft) {
				const draftRisk = draftPolicyDetails?.risks?.filter(r => r.code === risk.code);
				if (draftRisk?.length > 0) {
					let obj = {};
					draftRisk?.forEach((_, idx) => {
						obj = {
							...obj,
							[`risks-${i}-${idx}`]: risk
						};
					});
					return obj;
				}
				if (draftRisk?.length <= 0) {
					if (risk.minCount && !risk.doNotDisplay) {
						return { [`risks-${i}-0`]: risk };
					}
				}
			}
			// Else load it according to the portal setting.
			else {
				// Enable this if condition if the risk min count has to be checked on initial rendering
				// eslint-disable-next-line no-lonely-if
				if (risk.minCount && !risk.doNotDisplay) {
					return { [`risks-${i}-0`]: risk };
				}
			}
		});

	dispatch(setRisks([...currRisks]));

	// If loading the data from policy draft,
	// get the formvalues from the policy draft and return it.
	formValuesFromDraftedPolicy = getValuesToBePreFilled({
		draftPolicy,
		isPolicyDraft,
		draftPolicyDetails,
		values: formValuesFromDraftedPolicy,
		currRisks,
		channelProduct: currRisksObj,
		resumePolicyPathNames
	});

	return { formValuesFromDraftedPolicy };
};

// This is method to prefill the data
export const getValuesToBePreFilled = ({
	draftPolicy,
	isPolicyDraft,
	draftPolicyDetails,
	values,
	currRisks,
	channelProduct,
	resumePolicyPathNames
}) => {
	if (isPolicyDraft) {
		const { activeStep, productOptionInstanceId, riskPageNo, productInstanceId, collsFrequency } =
			draftPolicyDetails;

		values = {
			activeStep,
			productOptionInstanceId,
			riskPageNo,
			productInstanceId,
			collsFrequency
		};

		// Setting risks formvalues...
		currRisks.forEach(risk => {
			const keys = Object.keys(risk || {});
			keys.forEach((key, idx) => {
				const draftRisks = draftPolicyDetails?.risks?.filter(x => x.code === risk[key].code);
				const attributes = draftRisks?.[idx]?.attributes;

				if (attributes?.length) {
					attributes.forEach(d => {
						// If the attribute dataType is Lookup List = 28, we set input-value and value else we set only the value
						if (d.dataType === 28) {
							return (values = {
								...values,
								[`${key}-${d.name}`]: d.value,
								[`${key}-${d.name}-input-value`]: d.value
							});
						} else if (d.value) {
							values = {
								...values,
								[`${key}-${d.name}`]: d.value
							};
						}
					});
				}
			});
		});

		// Setting factors formvalues...
		channelProduct?.attributes?.forEach(ft => {
			const factorValue = draftPolicyDetails?.factors?.find(factor => factor?.code === ft?.code)?.value;
			values = {
				...values,
				[`factors-0-${ft.name}`]: factorValue
			};
		});

		// Setting activeStep...
		// If under writer continuing the reffered policy, then set activeStep = 0
		// If user continuing the reffered policy, then set activeStep = 1
		const _activeStep =
			draftPolicy?.draftType === resumePolicyPathNames.resumeUCReferredPolicy
				? 1
				: draftPolicy?.draftType === resumePolicyPathNames.resumeUWCEndorsement ||
					  draftPolicy?.draftType === resumePolicyPathNames.resumeUCQuotePolicy ||
					  draftPolicy?.draftType === resumePolicyPathNames.resumeUWCReferredPolicy
					? 0
					: activeStep;

		// Setting riskPageNo...
		// If there are factors, then set riskPageNo = 0 else 1.
		// If there draft type is policy draft, then set riskPageNo
		const _riskPageNo =
			draftPolicy?.draftType === resumePolicyPathNames.resumePolicyDraft
				? riskPageNo
				: channelProduct?.attributes && channelProduct?.attributes?.length > 0
					? 0
					: 1;

		values = {
			...values,
			activeStep: _activeStep,
			riskPageNo: _riskPageNo
		};
	}

	return values;
};

export const initApiCalls = ({
	dispatch,
	toast,
	channelProducts,
	setFactors,
	setRisks,
	getDeclaration,
	getAttachments,
	setInitialApiCallsResponse,
	productId,
	getProductPayment,
	clearFormForNewProduct,
	isPolicyDraft,
	draftPolicyDetails,
	draftPolicy,
	resumePolicyPathNames
}) => {
	const handleApiCallError = (reject, error) => {
		toast.error('We have a problem loading site. Please wait a few minutes before you try again.', {
			autoClose: 6000
		});
		dispatch(setInitialApiCallsResponse({ status: 'failed' }));
		reject(error);
	};

	// eslint-disable-next-line no-async-promise-executor
	return new Promise((resolve, reject) => {
		const promises = [];
		promises.push(dispatch(clearFormForNewProduct()));
		promises.push(dispatch(getDeclaration({ productId })));
		promises.push(dispatch(getAttachments({ productId })));
		promises.push(
			dispatch(
				getProductPayment({
					productInstanceId: productId,
					agentCompanyInstanceId:
						store.getState().fuse?.form?.referenceTokenDetails?.agentDetails?.companyInstanceId
				})
			)
		);

		if (promises.length > 0) {
			dispatch(setInitialApiCallsResponse({ status: 'pending' }));

			try {
				Promise.all(promises).then(allValues => {
					if (allValues.some(value => value.error)) {
						handleApiCallError(reject);
						return;
					}

					const { formValuesFromDraftedPolicy } = setRiskFields({
						dispatch,
						channelProducts,
						setFactors,
						setRisks,
						productId,
						isPolicyDraft,
						draftPolicyDetails,
						draftPolicy,
						resumePolicyPathNames
					});

					// WARNING - Do not change the position of dispatching this status.
					dispatch(setInitialApiCallsResponse({ status: 'success' }));

					resolve({ formValuesFromDraftedPolicy });
				});
			} catch (error) {
				handleApiCallError(reject);
			}
		}
	});
};

export const getChannelSettingByKey = key => {
	const settingValue = store
		.getState()
		?.fuse?.form?.channelSettings?.value?.channelSettings.find(_key => _key.key === key)?.value;
	return settingValue;
};

export const getChannelContentsByKey = key => {
	const contentValue = store
		.getState()
		?.fuse?.form?.channelSettings?.value?.channelContents.find(_key => _key.key === key)?.value;
	return contentValue;
};

// Function triggers the scrollIntoView of the first error elements
export const handleScroll = errors => {
	const elementsName = Object.keys(errors)?.[0];
	const elementScroll = document.querySelector(`[name="${elementsName}"]`);
	if (elementScroll instanceof HTMLElement) {
		const elementPosition = elementScroll.getBoundingClientRect().top;
		const offsetPosition = elementPosition - 100;
		window.scrollBy({
			top: offsetPosition,
			behavior: 'smooth'
		});
	}
};

export const getPayButtonName = (productPriceDetails, paymentButtonText) => {
	return productPriceDetails?.isReviewRequired ? 'Submit' : paymentButtonText || 'Pay Now';
};

export const getSelectedProductOptionDetails = (channelProducts, productInstanceId, productOptionInstanceId) => {
	const selectedProduct = channelProducts?.value?.find(prod => prod.product.instanceId === productInstanceId);
	const selectedProductOption = selectedProduct?.options?.find(
		e => e?.option?.instanceId === productOptionInstanceId
	)?.option;
	return {
		productName: selectedProduct?.name,
		optionName: selectedProductOption?.name
	};
};

export const getPolicyHolderDetails = (formValues, risks, factors, getAttributeValueByCode) => {
	return {
		phPhoneNumber: getAttributeValueByCode(null, 'PHO-29', formValues, risks, factors)?.value,
		phPhoneNumberCode: getAttributeValueByCode(null, 'PHO-30', formValues, risks, factors)?.value,
		phEmail: getAttributeValueByCode(null, 'PHO-17', formValues, risks, factors)?.value,
		phFirstName: getAttributeValueByCode(null, 'PHO-1', formValues, risks, factors)?.value,
		phLastName: getAttributeValueByCode(null, 'PHO-2', formValues, risks, factors)?.value
	};
};

// This method used to waiting for getReferralAgentData API to be complete
export const waitForReferralAgentData = () => {
	return new Promise((resolve, reject) => {
		const checkTokenStatus = () => {
			const tokenStatus = store.getState().fuse?.form?.referenceTokenDetails?.status;
			if (tokenStatus === 'success') {
				resolve();
			} else if (tokenStatus === 'failed') {
				reject(new Error('Token is invalid'));
			} else {
				setTimeout(checkTokenStatus, 100);
			}
		};
		checkTokenStatus(); // Start checking the token status immediately
	});
};

// Function validating the response of login and productInstanceIds
export const validateAgent = (productInstanceId, referenceTokenDetails) => {
	const agentLoginResponse = store.getState().fuse?.form?.agentLoginResponse;
	const { agentSellingCode, jwtToken, validateAgentResponses } = agentLoginResponse?.value || {};

	// Verifying against login
	if (agentSellingCode && jwtToken) {
		// If the productInstanceId matches with the validateAgentResponses, then returns true
		if (
			productInstanceId?.length &&
			productInstanceId?.every(instanceId =>
				validateAgentResponses?.map(instanceID => instanceID?.productInstanceId)?.includes(instanceId)
			)
		) {
			return true;
		}
		return false;
	}

	// Verifying against selling token
	if (referenceTokenDetails?.status === 'success') {
		if (
			productInstanceId?.length &&
			productInstanceId?.every(instanceId =>
				referenceTokenDetails?.agentDetails?.validateAgentResponse
					?.map(instanceID => instanceID?.productInstanceId)
					?.includes(instanceId)
			)
		) {
			return true;
		}
		return false;
	}

	return false;
};

// To check whether the login is required to onboard or not
export const isLoginRequired = navigate => {
	const mandateLogin = getChannelSettingByKey('Mandate-Login') === 'true';
	const agentLoginResponse = store.getState().fuse?.form?.agentLoginResponse;
	const { agentSellingCode, jwtToken } = agentLoginResponse?.value || {};

	if (mandateLogin) {
		// If the user is already logged in, then no need to login again
		if (agentSellingCode && jwtToken) {
			return false;
		}
		// Else the login is required
		navigate('/agent/login');
		return true;
	}

	return false;
};

// The "clearWhen" denotes that the value of this field is cleared when the user modifies anything in the connected attribute.
// Ex - prefix = risks-0-0, fieldName = 'Select Bank'.
// Cross-risk connections are not supported. Connections are always from the current risk to the current risk. Ex - risks-0-0 to risks-0-0.
export const handleClearWhen = ({ values, risks, prefixName, fieldName }) => {
	// The current risk. Ex - risks-0-0.
	const currentRisk = risks?.find(risk => Object.prototype.hasOwnProperty.call(risk, prefixName));
	// The attributes of the current risk
	const clonedAttributes = currentRisk?.[prefixName]?.attributes ? [...currentRisk[prefixName].attributes] : [];
	// The current attribute which the user is editing
	const currentField = clonedAttributes.find(att => att.name === fieldName);
	let clonedFormValues = { ...values };

	// Finding the attributes where the current field ClearWhen are linked to
	const fieldsMatchingWithClearWhen = clonedAttributes.filter(att => {
		const clearWhenFields = att?.lookUps ? JSON.parse(att.lookUps)?.['ClearWhen']?.split(',') : []; // Ex - ['Select Bank ~ PBA-1409', 'Select Branch ~ PBA-1410']
		const extractingAttributeCodes = clearWhenFields?.map(field => field?.split('~')[1]?.trim()); // Ex - ['PBA-1409', 'PBA-1410']
		return extractingAttributeCodes?.some(field => field === currentField.code); // Ex - true if the currentField code (PBA-1409) matches with extractingAttributeCodes
	});

	if (fieldsMatchingWithClearWhen.length) {
		fieldsMatchingWithClearWhen.forEach(att => {
			// If the attribute dataType is Lookup List = 28, we clear dd-options, input-value and value else we clear only the value
			if (att.dataType === 28) {
				clonedFormValues = {
					...clonedFormValues,
					[`${prefixName}-${att.name}-dd-options`]: [],
					[`${prefixName}-${att.name}`]: '',
					[`${prefixName}-${att.name}-input-value`]: ''
				};
			} else {
				clonedFormValues = {
					...clonedFormValues,
					[`${prefixName}-${att.name}`]: ''
				};
			}
		});
	}

	return clonedFormValues;
};

const debouncedSearchLookups = debounce((payload, prefix, field, linkedLookupStringValue, linkedLookupInstanceId) => {
	return new Promise((resolve, reject) => {
		const { formValues: values } = store.getState().fuse.form;
		store
			.dispatch(searchLookups({ payload, instanceId: linkedLookupInstanceId }))
			.then(response => {
				const items = response.payload?.items || [];
				store.dispatch(
					saveForm({
						...values,
						[`${prefix}-${field.name}-dd-options`]: items.map(item => ({
							label: item[linkedLookupStringValue],
							value: item[linkedLookupStringValue],
							instanceId: item.instanceId
						}))
					})
				);
				if (!items || items?.length <= 0) {
					toast.warning('No option(s) found.', {
						autoClose: 4000,
						position: 'top-center'
					});
				}
				resolve();
			})
			.catch(err => {
				reject(err);
			});
	});
}, 800); // An API call will be made only after a specified number of seconds since the user last typed

// Ex - eventTarget = Handle change event, newVaule = The value that user enters on Autocomplete textbox
// Ex - field = The current attribute obj that user is editing, prefix = risks-0-0
export const searchLookup = (eventTarget, newValue, field, prefix) => {
	// Perform only if the datatype is Lookup String & Lookup is confirgured for the attribute. 28 = Lookup String.
	if (field?.dataType === 28 && field?.lookUps) {
		const searchLookupResponse = store.getState().fuse?.form?.searchLookupResponse;

		// If the searchLookupResponse is pending, then return
		// This is to avoid editing the text while the API is in progress
		if (searchLookupResponse?.status === 'pending') return;

		const { risks, factors, formValues: values } = store.getState().fuse.form;

		const valuesAfterClearWhen = handleClearWhen({ values, risks, prefixName: prefix, fieldName: field.name });

		// Clearing old data of the current Autocomplete attribute
		// dd-options - To save the dropdown options
		// input-value - To save the text that user types on auto complete input
		store.dispatch(
			saveForm({
				...values,
				...valuesAfterClearWhen,
				[`${prefix}-${field.name}-dd-options`]: [],
				[`${prefix}-${field.name}`]: '',
				[`${prefix}-${field.name}-input-value`]: eventTarget.target.value
			})
		);

		// Returning if the newValue's length is less than 4 characters
		if (newValue?.length < 4) return;

		// The current Autocomplete attribute's lookup fields
		const { LinkedLookup: linkedLookup, CodeFrom: codeFrom } = JSON.parse(field?.lookUps) || {};
		// Ex - linkedLookup - Bank ~ e4c8d4cf-278a-370a-d4b5-302fc30621ac ~ value1
		const linkedLookupInstanceId = linkedLookup?.split('~')?.[1]?.trim(); // e4c8d4cf-278a-370a-d4b5-302fc30621ac
		const linkedLookupStringValue = linkedLookup?.split('~')?.[2]?.trim(); // value1
		// Ex - CodeFrom - Select Bank ~ PBA-1409
		const codeFromToCode = codeFrom?.split('~')[1]?.trim(); // PBA-1409

		// Get the value of linked code attribute
		const codeFromAttributeValue = getAttributeValueByCode(prefix, codeFromToCode, values, risks, factors)?.value;

		// If “Linked Lookup" is connected to the lookup code, whatever the user types in the search box will be used as the code for API filters.
		let payload = {
			distinct: true,
			fields: ['Code', 'Value1', 'Value2', 'Value3'],
			filters: [['Code', newValue]]
		};

		// If “Linked Lookup" is connected to the lookup value, whatever the user types in the search box will be used as the value for API filters, and the code will be fetched from the linked "codeFrom" attribute.
		if (codeFromToCode) {
			payload = {
				...payload,
				filters: [
					['Code', codeFromAttributeValue],
					[linkedLookupStringValue, newValue]
				]
			};
		}

		// Call the searchLookup API only if the code exists in the filter
		if (payload?.filters[0][1]) {
			debouncedSearchLookups(payload, prefix, field, linkedLookupStringValue, linkedLookupInstanceId);
		}
	}
};

// Function to add the attribute attachments
export const addAttributeAttachments = ({
	policyDetails,
	risks,
	attributeAttachments,
	addPolicySaleAttachmentsRequest,
	dispatch,
	values,
	attachments
}) => {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve, reject) => {
		let allRisksAttributes = [];

		// Looping through all risks and getting all attributes where linkedAttachmentOption is configured
		risks?.forEach(risk => {
			risk &&
				Object.keys(risk).forEach(prefix => {
					allRisksAttributes = risk[prefix]
						? allRisksAttributes?.concat(
								risk[prefix]?.attributes
									?.filter(attr => attr.linkedAttachmentOption)
									?.map(d => ({ ...d, prefix })) || []
							)
						: [];
				});
		});

		// Get all options from the Product attribute's attactments
		const attributeAttachmentOptions = attributeAttachments?.map(att => att.options)?.flat();

		// Filter out all the attributes where the configured linkedAttachmentOption does not exist in the Product attachment options.
		const isAttributeMatchWithAttachment = allRisksAttributes.filter(attr => {
			if (attr.linkedAttachmentOption) {
				return attributeAttachmentOptions.some(
					// Ex. linkedAttachmentOption = "Proof of Banking Details ~ Three months of bank statement ~ 0656a76a-57f7-1c4f-6ddc-f2ebd8f70a73"
					f => f.instanceId === attr.linkedAttachmentOption.split('~')[2].trim() // Ex. "0656a76a-57f7-1c4f-6ddc-f2ebd8f70a73"
				);
			}
			return false;
		});

		let attachmentDetails = [];

		// If the attribute is match with attachment, then add the attachment details
		if (isAttributeMatchWithAttachment.length) {
			allRisksAttributes?.forEach(attr => {
				// Get the instanceId of linked attachment attribute option
				// Ex. "Proof of Banking Details ~ Three months of bank statement ~ 0656a76a-57f7-1c4f-6ddc-f2ebd8f70a73"
				const linkedAttachmentInstanceID = attr.linkedAttachmentOption.split('~')[2].trim(); // Ex. "0656a76a-57f7-1c4f-6ddc-f2ebd8f70a73"

				// Getting the JSON object of the attachment's attributes from the values.
				const { fileName, link, sizeb } = JSON.parse(values[`${attr.prefix}-${attr.name}-obj`]) || {};

				// Extracting the blobName from the link Ex. "https://briiskidev.blob.core.windows.net/6344ecf1/8932f9dd-b391-49fe-b0bb-c64d9ca2ddcc"
				// Splitting the link by '/' and getting the last element (GUID) of the array
				const blobLink = link?.split('/');
				const blobName = blobLink?.[blobLink?.length - 1] || ''; // Ex. "8932f9dd-b391-49fe-b0bb-c64d9ca2ddcc"

				// Appending the each attachment details to attachmentDetails array to send to API
				attachmentDetails.push({
					fileName,
					fileExtension: fileName?.substring(fileName?.lastIndexOf('.')) || '.png',
					blobLink: link,
					blobName,
					sizeb,
					productAttachmentOptionInstanceId: linkedAttachmentInstanceID
				});
			});

			try {
				const payload = await addPolicySaleAttachmentsRequest(
					{
						policySaleReference: policyDetails.policySaleReference,
						policyReferences: policyDetails?.policies?.map(policyRef => policyRef.policyReference) // Passing the array of policy references.
					},
					values,
					attachments,
					attachmentDetails
				);
				if (payload?.policyAttachments?.length) {
					await dispatch(addPolicySaleAttachmentsBase64(payload));
					resolve();
				}
			} catch (err) {
				toast.warning('Error occured. Please try again.', {
					autoClose: 4000,
					position: 'top-center'
				});
			}
		} else {
			resolve();
		}
	});
};

// Function to add the attachments to the createPolicySale
export const addAttachments = ({ policyDetails, addPolicySaleAttachmentsRequest, values, attachments, dispatch }) => {
	new Promise((resolve, reject) => {
		addPolicySaleAttachmentsRequest(
			{
				policySaleReference: policyDetails.policySaleReference,
				policyReferences: policyDetails?.policies?.map(policyRef => policyRef.policyReference) // Passing the array of policy references.
			},
			values,
			attachments
		)
			.then(async data => {
				if (data?.policyAttachments?.length) {
					await dispatch(addPolicySaleAttachmentsBase64(data));
					resolve();
				}
			})
			.catch(e => {
				toast.error('Attachments are missing. Please add and try again.', {
					autoClose: 5000
				});
			});
	});
};
